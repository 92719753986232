import { useEffect, useState } from 'react';
import requests from 'helpers/requests';
import { UserData } from '@redux/slices/initLoad/types';

export const useDirectorie = () => {
  const [data, setData] = useState<UserData[]>([]);
  const [roles, setRoles] = useState<UserData[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const getInitData = async (page = 1) => {
    try {
      setLoading(true);
      const response = await requests.get('v2/directories?page=' + page);
      setData(response.data.data);
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      console.log(error, 'error init');
      console.error('Error verifying authentication:', error);
    }
  };

  const getPage = async (page = 1) => {
    try {
      setLoading(true);
      const response = await requests.get('v2/directories?page=' + page);
      return response.data.data;
    } catch (error: any) {
      setLoading(false);
      console.log(error, 'error init');
      console.error('Error verifying authentication:', error);
    }
  };

  const getRoles = async () => {
    try {
      setLoading(true);
      const response = await requests.get('roles');
      setRoles(response.data);
    } catch (error: any) {
      setLoading(false);
      console.log(error, 'error init');
      console.error('Error verifying authentication:', error);
    }
  };

  useEffect(() => {
    getInitData();
    getRoles();
  }, []);

  return { data, loading, roles, getInitData, getPage };
};
