import Layout from '@components/layout';
import { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import './style.css';
import { useDirectorie } from '@hooks/useDirectorie';
import { useSelector } from 'react-redux';
import { AppStateType } from '@redux/store';
import { UserData } from '@redux/slices/initLoad/types';
import OpenAI from 'openai';
import { interest } from '@screens/home/interest';
import { log } from 'console';

interface UserDataWithMatch extends UserData {
  isMatched?: boolean; // Cambiar a isMatched para indicar si hay una coincidencia
}

const LoginForm = () => {
  const { user, scroll } = useSelector((state: AppStateType) => state.initLoad);
  const { data, roles, getPage } = useDirectorie();

  const [filteredData, setFilteredData] = useState<UserDataWithMatch[]>(
    data.map((item) => ({ ...item, isMatched: false })),
  ); // Inicializa con isMatched

  const [rol, setRol] = useState('');
  const [filterInterest, setfilterInterest] = useState('');

  const normalizeText = (text: any) => {
    return text.normalize('NFD').replace(/[\u0300-\u036f]/g, ''); // Remove diacritics
  };

  const [page, setPage] = useState(1);

  useEffect(() => {
    if (scroll > page * 400) {
      setPage((prevPage) => prevPage + 1);
    }
  }, [scroll]);

  useEffect(() => {
    if (page > 1) {
      const controller = new AbortController(); // Controlador para cancelar solicitudes previas
      const signal = controller.signal;

      const fetchPageData = async () => {
        try {
          const data = await getPage(page);
          setFilteredData((prevData) => [...prevData, ...data]);
        } catch (err) {}
      };

      fetchPageData();

      return () => {
        controller.abort(); // Cancela la solicitud anterior si el efecto se vuelve a ejecutar
      };
    }
  }, [page]);

  useEffect(() => {
    if (rol === '') {
      setFilteredData(data);
    } else {
      const normalizedSearch = normalizeText(rol.toLowerCase());
      const filteredUser = data.filter((g) =>
        normalizeText(g.rol.toLowerCase()).includes(normalizedSearch),
      );
      setFilteredData(filteredUser);
    }
  }, [rol]);

  useEffect(() => {
    if (filterInterest === '') {
      setFilteredData(data);
    } else {
      const normalizedSearch = normalizeText(filterInterest.toLowerCase());
      const filteredUser = data.filter((g) => {
        if (g.interest) {
          return normalizeText(g.interest.toLowerCase()).includes(
            normalizedSearch,
          );
        }
      });
      setFilteredData(filteredUser);
    }
  }, [filterInterest]);

  const openai = new OpenAI({
    apiKey:
      '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', // Reemplaza con tu clave de API
    dangerouslyAllowBrowser: true,
  });

  const handleIAFilter = async () => {
    if (!user) return;

    // Crear el prompt
    const prompt = `
    Tengo un usuario con la siguiente información:
    - Oferta: ${user.what_offer}, 
    - Buscando: ${user.what_looking}, 
    - Intereses: ${user.interest}.
  
    Encuentra personas que podrían hacer negocios o conectar basado en lo que oferta y busca. 
    Asegúrate de que las coincidencias sean relevantes y basadas en la similitud de la oferta y la búsqueda.
  
    Aquí están las personas en la base de datos:
    ${data
      .slice(0, 400)
      .map(
        (item, index) =>
          `Persona ${index + 1}: Oferta: ${
            item.what_offer || 'No especificado'
          }, Buscando: ${item.what_looking || 'No especificado'}, Intereses: ${
            item.interest || 'No especificado'
          }.`,
      )
      .join('\n')}
    
    Dame las coincidencias en el siguiente formato, minimo 20 ordenandolas de primeras las más altas:
    - Persona 1
    - Persona 2
    ...
  `;

    console.log('Prompt enviado a OpenAI:', prompt);

    try {
      const response = await openai.chat.completions.create({
        model: 'gpt-3.5-turbo',
        messages: [
          { role: 'system', content: 'Filtrar los mejores resultados.' },
          { role: 'user', content: prompt },
        ],
        temperature: 0.1, // Ajustar la temperatura aquí
      });

      console.log('OpenAI Response:', response.choices[0].message.content);

      if (response.choices.length > 0 && response.choices[0].message.content) {
        const matchData = response.choices[0].message.content
          .split('\n')
          .map((line) => {
            const match = line.match(/Persona (\d+)/); // Asegúrate de que este regex se ajuste a tu formato
            return match ? parseInt(match[1], 10) - 1 : null; // Asegúrate de que esto sea correcto
          })
          .filter(Boolean) as number[];

        // Comprobar si hay coincidencias
        if (matchData.length > 0) {
          const matchedUsers = matchData.map((index) => ({
            ...data[index],
            isMatched: true, // Marcar como coincidencia
          }));

          // Filtrar usuarios no coincidentes
          const unmatchedUsers = data.filter(
            (_, index) => !matchData.includes(index),
          );

          // Combinar los usuarios coincidentes y no coincidentes
          const finalFilteredData = [...matchedUsers, ...unmatchedUsers];

          // Actualizar el estado con los datos filtrados
          setFilteredData(finalFilteredData);
        } else {
          console.log('No matches found from OpenAI response.');
          setFilteredData(data); // Muestra todos los datos si no hay coincidencias
        }
      } else {
        console.log('No valid response from OpenAI.');
        setFilteredData(data); // Muestra todos los datos si no hay coincidencias
      }
    } catch (error) {
      console.error('Error al usar OpenAI:', error);
    }
  };

  useEffect(() => {
    // Establecer datos iniciales y ordenarlos
    setFilteredData(data.map((item) => ({ ...item, isMatched: false })));
  }, [data]);

  const CardEntrepreneur = ({ data }: { data: UserDataWithMatch }) => {
    const defaultImage =
      'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png';

    return (
      <Link
        to="/directorie/profile"
        state={{ profile: data }}
        className="content-card-entrepreneur"
      >
        <img
          src={
            data.image
              ? `https://api.cervezaton.com/api/image/resize/${data?.image
                  .split('/')
                  .pop()}/200/300`
              : defaultImage
          }
          onError={(e) => {
            (e.target as HTMLImageElement).src = defaultImage;
          }}
          className="fade-in"
          alt={`${data.name}'s profile`}
        />
        <div className="entrepreneur-caption">
          <p className="title">{data.name}</p>
          <p className="rol">
            {' '}
            {data.rol} {data.company ? `en ${data.company}` : ''}{' '}
          </p>
          {data.isMatched && <span className="badge">Posible Networking</span>}{' '}
          {/* Badge para coincidencias */}
        </div>
      </Link>
    );
  };

  return (
    <Layout showBottomNav={true}>
      <div className="filter-ia-container">
        <button className="filter-ia-button" onClick={handleIAFilter}>
          Match con IA
        </button>
      </div>

      <div className="direcotorie-container">
        <h2>Directorio de negocios</h2>

        <div className="filter-container">
          <select
            name=""
            id=""
            onChange={(item) => {
              setfilterInterest(item.target.value);
            }}
          >
            <option value="">Por interés</option>
            {interest.map((item, index) => (
              <option key={index} value={item}>
                {item}
              </option>
            ))}
          </select>

          <select
            name=""
            id=""
            onChange={(item) => {
              setRol(item.target.value);
            }}
          >
            <option value="">Por cargo</option>
            {roles.map((item: any, index) => {
              return (
                <option key={index} value={item}>
                  {item}
                </option>
              );
            })}
          </select>

          <button
            className="filter-button"
            onClick={() => [setRol(''), setfilterInterest('')]}
          >
            Limpiar
          </button>
        </div>
        <br />
        <div className="entrepreneur-container">
          {filteredData.map((item, index) => (
            <CardEntrepreneur key={index} data={item} />
          ))}
        </div>
      </div>
    </Layout>
  );
};

export default LoginForm;
